<template>
	<div>
		<div class="d-flex">
			<e-datepicker class="mr-2" v-model="date" id="date"></e-datepicker>
            <button @click="init_component" class="btn btn-primary">{{ $t('global.rechercher') }}</button>
		</div>

		<CustomTable
			ref="table"
			id_table="suggestion_deplacement"
			:busy.sync="table_busy"
			primaryKey="suggestion_id"
			:hide_if_empty="true"
			:items="suggestions"
			:externSlotColumns="['horse.horse_nom']"
		>
			<template v-slot:[`custom-slot-cell(horse.horse_nom)`]="{ data }">
				<router-link :key="data.horse.horse_id" :to="{ name: 'HorseFicheInfo', params: { horse_id: data.horse.horse_id }}">
					{{ data.horse.horse_nom }}<br>
				</router-link>
			</template>
		</CustomTable>
	</div>
</template>

<script type="text/javascript">
	import Shutter from '@/mixins/Shutter.js'
	import Deplacement from "@/mixins/Deplacement.js"
	import Navigation from "@/mixins/Navigation.js"
	import ShutterDeplacement from '@/mixins/shutters-manager/Deplacement.js'
	import { EventBus } from 'EventBus'

	export default {
		name: 'SuggestionListe',
		mixins: [Shutter, Deplacement, Navigation, ShutterDeplacement],
		data () {
			return {
				date: new Date(),
				table_busy: false,
				suggestions: [],
				events_tab: {
					'TableAction::goToAddDeplacementFromSuggestion': this.openAjoutDeplacement,
				}
			}
		},

		methods: {
			async init_component() {
				this.table_busy = true
				this.suggestions = await this.getSuggestionDeplacements(this.date.toDateInputValue())
				this.table_busy = false
			},

			openAjoutDeplacement(suggestions) {
				this.openAjoutDeplacementFromSuggestion(suggestions, this.date)
			},

			deplacementFormSubmit() {
				this.init_component()
				this.$refs.table.unselectAll()
			}
		},

		components: {
			HeaderTab: () => import('@/components/HeaderTab'),
			CustomTable : () => import('GroomyRoot/components/Table/CustomTable'),
            DateRange: () => import('GroomyRoot/components/Inputs/DateRange')
		}
	}

</script>